import { sharedOptions } from "./sharedOptions";

import font from "./font";

declare let ShopifyBuy: {
  UI: {
    onReady: (client: unknown) => Promise<{
      createComponent: (
        name: string,
        opts: Record<string, unknown>,
      ) => Promise<void>;
    }>;
  };
  buildClient: (opts: unknown) => unknown;
};

const products = {
  boxset: "9627256127835",
  past: "9627240333659",
  present: "9627240694107",
  future: "9627241087323",
  "past-present-future": "9575004766555",
} as const;

function css(strings: TemplateStringsArray, ...keys: string[]) {
  const lastIndex = strings.length - 1;
  return (
    strings.slice(0, lastIndex).reduce((p, s, i) => p + s + keys[i], "") +
    strings[lastIndex]
  );
}

const scriptURL =
  "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
// @ts-expect-error can't be bothered
if (window.ShopifyBuy) {
  // @ts-expect-error can't be bothered
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (window.ShopifyBuy.UI) {
    void ShopifyBuyInit();
  } else {
    loadScript();
  }
} else {
  loadScript();
}
function loadScript() {
  const script = document.createElement("script");
  script.async = true;
  script.src = scriptURL;
  (
    document.getElementsByTagName("head")[0] ||
    document.getElementsByTagName("body")[0]
  ).appendChild(script);
  script.onload = ShopifyBuyInit;
}
async function ShopifyBuyInit() {
  const client = ShopifyBuy.buildClient({
    domain: "checkout.britpop.online",
    storefrontAccessToken: "3ab8447bbcd2da4b36603fe9453ca3ba",
  });
  const ui = await ShopifyBuy.UI.onReady(client);
  await Promise.all([
    ui.createComponent("product", {
      id: products.boxset,
      node: document.getElementById("product-boxset"),
      moneyFormat: "%C2%A3%7B%7Bamount%7D%7D",
      options: {
        ...sharedOptions,
      },
    }),
    ui.createComponent("product", {
      id: products.past,
      node: document.getElementById("product-past"),
      moneyFormat: "%C2%A3%7B%7Bamount%7D%7D",
      options: { ...sharedOptions },
    }),
    ui.createComponent("product", {
      id: products.present,
      node: document.getElementById("product-present"),
      moneyFormat: "%C2%A3%7B%7Bamount%7D%7D",
      options: { ...sharedOptions },
    }),
    ui.createComponent("product", {
      id: products.future,
      node: document.getElementById("product-future"),
      moneyFormat: "%C2%A3%7B%7Bamount%7D%7D",
      options: { ...sharedOptions },
    }),
    ui.createComponent("product", {
      id: products["past-present-future"],
      node: document.getElementById("product-past-present-future"),
      moneyFormat: "%C2%A3%7B%7Bamount%7D%7D",
      options: {
        ...sharedOptions,
      },
    }),
  ]);
}

const insertCss = (el: HTMLIFrameElement) => {
  const s = el.contentDocument?.createElement("style");
  if (!s) return;

  s.textContent = css`
    @font-face {
      font-family: "Avant Garde";
      src:
        local("ITC Avant Garde Gothic Std"),
        url("data:font/woff2;charset=utf-8;base64,${font}") format("woff2");
    }
    * {
      text-transform: uppercase;
    }
    html {
      display: contents;
      font-family: "Avant Garde" !important;
    }
    .shopify-buy__product__variant-img {
      margin-top: 4px;
    }
    .shopify-buy__product-img-wrapper {
      margin-bottom: auto;
      padding-top: 4px;
    }
    .shopify-buy__btn-wrapper {
      padding: 4px 0;
    }
    .shopify-buy__btn {
      padding: 15px 40px 12px;
    }
    .shopify-buy__btn:not([disabled]) {
      color: #231f20;
      outline: 1px solid #01894c;
      outline-offset: 2px;
      box-shadow:
        inset 1px 0 #01894c,
        inset -1px 0 #01894c,
        inset 0 1px #01894c,
        inset 0 -2px #01894c;
    }
    .shopify-buy__btn[disabled] {
      background-color: #2c2c2c88;
    }
    .shopify-buy__carousel-item {
      width: 48px;
    }
    .shopify-buy__product__variant-img {
      margin: 3px;
      width: calc(100% - 6px);
    }
    .shopify-buy__product__variant-img,
    .shopify-buy__carousel-item--selected {
      opacity: 1;
      outline: 1px solid #01894c;
      outline-offset: 2px;
    }
    .shopify-buy__product-description {
      color: #877f7b;
      font-family: "Avant Garde";
    }
    .shopify-buy__product-description ul {
      text-align: left;
      text-wrap: pretty;
    }
    .shopify-buy__cart-toggle {
      border-radius: 0 !important;
    }
    ::selection {
      color: #231f20;
      background: #01894c;
    }
  `;
  el.contentDocument?.head.appendChild(s);
};
setTimeout(() => {
  Object.keys(products).forEach((p) => {
    const parent = document.querySelector(`#product-${p}`)!;
    const obs = new ResizeObserver((entries) => {
      const entry = entries[0];
      const iframe = parent.querySelector("iframe");
      if (!iframe) return;
      iframe.height = `${entry.contentRect.height}px`;
    });

    obs.observe(parent);
  });
}, 0);
Object.keys(products).forEach((p) => {
  const parent = document.querySelector(`#product-${p}`)!;
  const observer = new MutationObserver((mutationList) => {
    for (const mutation of mutationList) {
      if (mutation.type === "childList") {
        const iframe = (mutation.target as HTMLElement)?.querySelector(
          "iframe",
        );
        if (!iframe) throw new Error("missing iframe");

        insertCss(iframe);
      }
    }
  });
  observer.observe(parent, { childList: true });
});

const obs = new MutationObserver((mutationList) => {
  for (const mutation of mutationList) {
    console.log(mutation);
    if (mutation.type === "childList") {
      const iframe = (
        mutation.target as HTMLElement
      )?.querySelector<HTMLIFrameElement>(
        ".shopify-buy-cart-wrapper > iframe, .shopify-buy-frame > iframe, .shopify-buy-frame--toggle > iframe",
      );
      if (!iframe) throw new Error("missing iframe");

      insertCss(iframe);
    }
  }
});

obs.observe(document.body, { childList: true, subtree: true });
