export const sharedOptions = {
  product: {
    templates: {
      img: '{{#data.currentImage}}<div class="{{data.classes.product.imgWrapper}}" data-element="product.imgWrapper"><img alt="{{data.currentImage.altText}}" data-element="product.img" class="{{data.classes.product.img}}" src="{{data.currentImage.srcLarge}}" srcset="{{data.currentImage.srcOriginal}} 2048w, {{data.currentImage.srcLarge}} 550w" /></div>{{/data.currentImage}}',
      //button: `<p style="color:#01894c">THIS IS A PRE-ORDER<br/>RELEASE DATE: FEBRUARY 27, 2025</p><div class="{{data.classes.product.buttonWrapper}}" data-element="product.buttonWrapper"><button {{#data.buttonDisabled}}disabled{{/data.buttonDisabled}} class="{{data.classes.product.button}} {{data.buttonClass}}" data-element="product.button">{{data.buttonText}}</button></div>`,
    },
    styles: {
      product: {
        "carousel-button": {
          display: "none",
        },
        "main-image-wrapper": {
          display: "none",
        },
      },
      title: {
        "font-family": "Avant Garde, sans-serif",
        "font-weight": "normal",
        color: "#f4ded3",
      },
      button: {
        "font-family": "Avant Garde, sans-serif",
        color: "#f4ded3",
        ":hover": {
          color: "#f4ded3",
          "background-color": "#017b44",
        },
        "background-color": "#01894c",
        ":focus": {
          "background-color": "#017b44",
        },
        "border-radius": "0px",
      },
      price: {
        "font-family": "Avant Garde, sans-serif",
        color: "#01894c",
      },
      compareAt: {
        "font-family": "Avant Garde, sans-serif",
        color: "#01894c",
      },
      unitPrice: {
        "font-family": "Avant Garde, sans-serif",
        color: "#01894c",
      },
    },
    width: "550px",
    layout: "vertical",
    contents: {
      img: true,
      imgWithCarousel: true,
      description: true,
    },
    text: {
      button: "Add to basket",
    },
  },
  productSet: {
    styles: {
      products: {
        "@media (min-width: 601px)": {
          "margin-left": "-20px",
        },
      },
    },
  },
  modalProduct: {
    contents: {
      img: false,
      imgWithCarousel: true,
      button: false,
      buttonWithQuantity: true,
    },
    // styles: {
    //   product: {
    //     "@media (min-width: 601px)": {
    //       "max-width": "100%",
    //       "margin-left": "0px",
    //       "margin-bottom": "0px",
    //     },
    //   },
    //   button: {
    //     "font-family": "Avant Garde, sans-serif",
    //     color: "#f4ded3",
    //     ":hover": {
    //       color: "#f4ded3",
    //       "background-color": "#017b44",
    //     },
    //     "background-color": "#01894c",
    //     ":focus": {
    //       "background-color": "#017b44",
    //     },
    //     "border-radius": "0px",
    //   },
    //   title: {
    //     "font-family": "Helvetica Neue, sans-serif",
    //     "font-weight": "bold",
    //     "font-size": "26px",
    //     color: "#4c4c4c",
    //     "text-wrap": "balance",
    //   },
    //   price: {
    //     "font-family": "Helvetica Neue, sans-serif",
    //     "font-weight": "normal",
    //     "font-size": "18px",
    //     color: "#4c4c4c",
    //   },
    //   compareAt: {
    //     "font-family": "Helvetica Neue, sans-serif",
    //     "font-weight": "normal",
    //     "font-size": "15.299999999999999px",
    //     color: "#4c4c4c",
    //   },
    //   unitPrice: {
    //     "font-family": "Helvetica Neue, sans-serif",
    //     "font-weight": "normal",
    //     "font-size": "15.299999999999999px",
    //     color: "#4c4c4c",
    //   },
    // },
    text: {
      button: "Add to cart",
    },
  },
  option: {},
  cart: {
    styles: {
      button: {
        "font-family": "Avant Garde, sans-serif",
        color: "#f4ded3",
        ":hover": {
          color: "#f4ded3",
          "background-color": "#017b44",
        },
        "background-color": "#01894c",
        ":focus": {
          "background-color": "#017b44",
        },
        "border-radius": "0px",
      },
      title: {
        color: "#2c2c2c",
        "font-family": "Avant Garde, sans-serif",
      },
      header: {
        color: "#2c2c2c",
      },
      lineItems: {
        color: "#2c2c2c",
      },
      subtotalText: {
        color: "#2c2c2c",
      },
      subtotal: {
        color: "#2c2c2c",
      },
      notice: {
        color: "#2c2c2c",
      },
      currency: {
        color: "#2c2c2c",
      },
      close: {
        color: "#2c2c2c",
        ":hover": {
          color: "#2c2c2c",
        },
      },
      empty: {
        color: "#2c2c2c",
      },
      noteDescription: {
        color: "#2c2c2c",
      },
      discountText: {
        color: "#2c2c2c",
      },
      discountIcon: {
        fill: "#2c2c2c",
      },
      discountAmount: {
        color: "#2c2c2c",
      },
      cart: {
        "background-color": "#f4ded3",
      },
      footer: {
        "background-color": "#f4ded3",
      },
    },
    text: {
      total: "Subtotal",
      button: "Checkout",
      title: "Basket",
    },
    popup: false,
    templates: {
      footer: `{{^data.isEmpty}}
            <div class="{{data.classes.cart.footer}}" data-element="cart.footer">
              {{#data.discounts}}
                <div class="{{data.classes.cart.discount}}" data-element="cart.discount">
                  <span class="{{data.classes.cart.discountText}}" data-element="cart.discountText">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" class="{{data.classes.cart.discountIcon}}" data-element="cart.discountIcon" aria-hidden="true">
                      <path d="M10.001 2.99856C9.80327 2.99856 9.61002 2.93994 9.44565 2.83011C9.28128 2.72029 9.15317 2.56418 9.07752 2.38155C9.00187 2.19891 8.98207 1.99794 9.02064 1.80405C9.05921 1.61016 9.1544 1.43207 9.29419 1.29228C9.43397 1.1525 9.61207 1.0573 9.80596 1.01874C9.99984 0.980171 10.2008 0.999965 10.3834 1.07562C10.5661 1.15127 10.7222 1.27938 10.832 1.44375C10.9418 1.60812 11.0005 1.80136 11.0005 1.99905C11.0005 2.26414 10.8952 2.51837 10.7077 2.70581C10.5203 2.89326 10.266 2.99856 10.001 2.99856ZM10.001 1.67062e-05H7.0024C6.87086 -0.000743818 6.74046 0.024469 6.61868 0.0742095C6.49691 0.12395 6.38614 0.19724 6.29275 0.289876L0.295655 6.28697C0.201972 6.37989 0.127614 6.49044 0.0768697 6.61224C0.0261256 6.73404 0 6.86468 0 6.99663C0 7.12857 0.0261256 7.25922 0.0768697 7.38102C0.127614 7.50282 0.201972 7.61336 0.295655 7.70628L4.29372 11.7043C4.38664 11.798 4.49718 11.8724 4.61898 11.9231C4.74078 11.9739 4.87143 12 5.00337 12C5.13532 12 5.26596 11.9739 5.38776 11.9231C5.50956 11.8724 5.62011 11.798 5.71303 11.7043C5.90294 11.5044 11.5102 5.89716 11.7101 5.70725C11.8028 5.61386 11.876 5.50309 11.9258 5.38132C11.9755 5.25954 12.0007 5.12914 12 4.99759V1.99905C12 1.46887 11.7894 0.96041 11.4145 0.585519C11.0396 0.210628 10.5311 1.67062e-05 10.001 1.67062e-05Z" />
                    </svg>
                    <span class="visuallyhidden">Discount:</span>
                    {{text}}
                  </span>
                  <span class="{{data.classes.cart.discountAmount}}" data-element="cart.discountAmount">{{amount}}</span>
                </div>
              {{/data.discounts}}
              <p class="{{data.classes.cart.subtotalText}}" style="margin-bottom:2em">NON-UK CUSTOMERS MAY BE CHARGED FOR TAX AND DUTY SEPARATELY BY THEIR SHIPPING PROVIDER, SEE <a style="color:#01894c" href="https://pe.usps.com/text/dmm300/Notice123.htm#_c432">HERE</a> FOR MORE INFORMATION ON US ORDERS.
              <p class="{{data.classes.cart.subtotalText}}" data-element="cart.total">{{data.text.total}}</p>
              <p class="{{data.classes.cart.subtotal}}" data-element="cart.subtotal">{{data.formattedTotal}}</p>
              {{#data.contents.note}}
                <div class="{{data.classes.cart.note}}" data-element="cart.note">
                  <label for="{{data.cartNoteId}}" class="{{data.classes.cart.noteDescription}}" data-element="cart.noteDescription">{{data.text.noteDescription}}</label>
                  <textarea id="{{data.cartNoteId}}" class="{{data.classes.cart.noteTextArea}}" data-element="cart.noteTextArea" rows="3"/>{{data.cartNote}}</textarea>
                </div>
              {{/data.contents.note}}
              <p class="{{data.classes.cart.notice}}" data-element="cart.notice">{{data.text.notice}}</p>
              <button class="{{data.classes.cart.button}}" type="button" data-element="cart.button">{{data.text.button}}</button>
            </div>
           {{/data.isEmpty}}`,
    },
  },
  toggle: {
    styles: {
      toggle: {
        "font-family": "Avant Garde, sans-serif",
        "background-color": "#01894c",
        ":hover": {
          "background-color": "#017b44",
        },
        ":focus": {
          "background-color": "#017b44",
        },
        "border-radius": "0",
      },
      count: {
        color: "#f4ded3",
        ":hover": {
          color: "#f4ded3",
        },
      },
      iconPath: {
        fill: "#f4ded3",
      },
    },
  },
  lineItem: {
    styles: {
      variantTitle: {
        color: "#2c2c2c",
      },
      title: {
        color: "#2c2c2c",
      },
      price: {
        color: "#2c2c2c",
      },
      fullPrice: {
        color: "#2c2c2c",
      },
      discount: {
        color: "#2c2c2c",
      },
      discountIcon: {
        fill: "#2c2c2c",
      },
      quantity: {
        color: "#2c2c2c",
      },
      quantityIncrement: {
        color: "#2c2c2c",
        "border-color": "#2c2c2c",
      },
      quantityDecrement: {
        color: "#2c2c2c",
        "border-color": "#2c2c2c",
      },
      quantityInput: {
        color: "#2c2c2c",
        "border-color": "#2c2c2c",
      },
    },
  },
};
